import React, { useEffect, useState } from "react";
import { Button, Layout, notification, Tag } from "antd";
import styles from "./IMTContent.module.less";
import { LoginRole } from "~/api/auth";
import CompanyListModal from "~/component/CompanyListModal";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { registerEvent } from "~/analytics";
import "./ph-response.css";
import * as ga from "~/contants/gaConstants";
import { MenuFoldOutlined } from "@ant-design/icons";

const mapStateToProps = (state: AuthState) => {
  const user = state.mainAppSlice.user;
  const userNameOrEmail = user.first_name ?? user.email ?? "";
  const userFirstChar = userNameOrEmail.substr(0, 1).toUpperCase();
  const companyCount = user.added_clients ?? 0;
  const { showSyncButtonTour } = state.mainAppSlice;
  const { updateCompanyList } = state.mainAppSlice;
  return {
    userDetail: user,
    steps: state.mainAppSlice.steps,
    activeRole: user.activeRole,
    userFirstChar,
    companyCount,
    showSyncButtonTour,
    isSyncing: state.modalSlice.isSyncing,
    syncInterval: state.modalSlice.syncInterval,
    currentTourStep: state.mainAppSlice.currentTourStep,
    currentStepKey: showSyncButtonTour
      ? state.mainAppSlice.steps[state.mainAppSlice.currentTourStep]?.key
      : "",
    updateCompanyList,
  };
};

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  empdetail: (a: any) => dispatch(action.auth.empDetailsSaved(a)),
  dochange: (company_id: any, role_id?: any) =>
    dispatch(action.auth.doChangeCompany(company_id)),
  setActiveRole: (role: any) => dispatch(action.auth.setActiveRole(role)),
  updateFirstLastName: (values: any) =>
    dispatch(action.auth.updateFirstLastName(values)),
  handleUpdateCompanyList: (val: boolean) => {
    dispatch(action.auth.handleUpdateCompanyList(val));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const { Content } = Layout;

interface Props extends PropsFromRedux {
  layoutRef?: React.Ref<any>;
  children?: any;
  fullwidth?: boolean;
  withoutMargin?: boolean;
}

const IMTContent: React.FC<any> = ({
  layoutRef,
  fullwidth,
  withoutMargin,
  userDetail,
  activeRole,
  children,
  dochange,
  handleUpdateCompanyList,
  updateCompanyList,
}: Props) => {
  const [companyListViewInfo, setcompanyListViewInfo] = useState({
    companyListView: false,
    updateList: false,
    addCliantModal: false,
  });
  const [showNav, setShoNav] = useState(false);
  const [firstChild, ...rest] = children;
  const userData = localStorage.getItem("imt__user");
  const userParseData = JSON.parse(userData!);
  const history = useHistory();

  const handleCompanyPopup = (visible: boolean) => {
    setcompanyListViewInfo((oldInfo) => ({
      ...oldInfo,
      companyListView: visible,
    }));
  };

  const handleRestoreState = () => {
    setcompanyListViewInfo((oldInfo) => ({
      ...oldInfo,
      updateList: false,
    }));
  };

  const visibleAddClint = (visible: boolean) => {
    const queryParams = new URLSearchParams(history.location.search);
    if (visible) {
      queryParams.set("addClient", "1");
    } else {
      if (queryParams.has("addClient")) {
        queryParams.delete("addClient");
      }
    }
    history.push(history.location.pathname + "?" + queryParams.toString());

    setcompanyListViewInfo((oldInfo) => ({
      ...oldInfo,
    }));
  };

  const handleSwitch = (comId: any, role: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.imtContent288
    );

    dochange({ company_id: comId, role_id: role });
  };

  const handleCompanyChange = async (companyId: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.imtContent289
    );

    const company = userDetail.company_details.find(
      (it: any) => it.id === companyId
    );
    if (company.name === userDetail.company_name) {
      notification.error({
        message: "Already login with this company",
      });
    } else {
      dochange({ company_id: companyId });
    }
  };
  // const handleCompanyList = () => {
  //   registerEvent(
  //     ga.EVENT_CATEGORY_BUTTON_CLICK,
  //     ga.EVENT_CLICK,
  //     ga.events.imtContent290
  //   );

  //   setcompanyListViewInfo((oldInfo) => ({
  //     ...oldInfo,
  //     addCliantModal: false,
  //     updateList: companyListViewInfo.companyListView ? true : false,
  //   }));
  // };

  return (
    <Layout
      className={withoutMargin ? styles.mainLayout : styles.mainLayoutMargin}
      ref={layoutRef}
      id="cqd-layout"
      style={{ width: "100%" }}
    >
      {/* <div
        className={styles.companyNameDiv}
        id="header"
        style={{
          backgroundColor:
            userParseData.activeRole === LoginRole.ADMIN
              ? "var(--color-admin)"
              : userParseData.activeRole === LoginRole.EMPLOYEE
              ? "var(--color-employee)"
              : userParseData.activeRole === LoginRole.CA
              ? "var(--color-ca)"
              : userParseData.activeRole === LoginRole.SUPERuSER
              ? "var(--color-superuser)"
              : userParseData.activeRole === LoginRole.DELIGATE
              ? "var(--color-deligateuser)"
              : "",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--processing-color)",
            height: "100%",
            alignItems: "center",
          }}
          className="nav-logo"
        >
          <img src="/logo-white@2x.png" alt="logo" style={{ height: "30px" }} />
        </div>

        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          {userParseData.company_name} |{" "}
          {userParseData.gstin ? userParseData.gstin : "Gst unregistered"}
        </div>

        <div
          style={{
            fontWeight: 600,
            fontSize: "13px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "8px",
          }}
        >
          {userParseData.activeRole !== LoginRole.EMPLOYEE ? (
            <Tag
              style={{
                minWidth: 70,
                textAlign: "center",
                borderRadius: "30px",
                fontWeight: 700,
                color:
                  userParseData.activeRole === LoginRole.ADMIN
                    ? "var(--admintext-color)"
                    : userParseData.activeRole === LoginRole.CA
                    ? "var(--catext-color)"
                    : userParseData.activeRole === LoginRole.SUPERuSER
                    ? "var(--superusertext-color)"
                    : userParseData.activeRole === LoginRole.DELIGATE
                    ? "var(--color-deligateuser)"
                    : "",
              }}
            >
              {userParseData.activeRole === LoginRole.ADMIN
                ? "Admin"
                : userParseData.activeRole === LoginRole.CA
                ? "CA"
                : userParseData.activeRole === LoginRole.SUPERuSER
                ? "Superuser"
                : userParseData.activeRole === LoginRole.DELIGATE
                ? "Deligate user"
                : null}
            </Tag>
          ) : null}
        </div>

        <div
          // className={styles.changeWrap}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <MenuFoldOutlined
            className="ph-menu"
            onClick={() => setShoNav(!showNav)}
          />
        </div>

        <div
          className={`switch-btn`}
          style={{
            height: "50px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            size="small"
            className={styles.changeBtn}
            style={{ margin: "0px" }}
            onClick={() => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.imtContent291
              );
              setcompanyListViewInfo((oldInfo) => ({
                ...oldInfo,
                companyListView: true,
              }));
            }}
          >
            Switch
          </Button>
        </div>
      </div> */}
      {firstChild}
      <Content
        className={fullwidth ? styles.fullWidthContent : styles.commonContent}
        // style={{overflow:'auto'}}
      >
        {rest}
      </Content>
      {/* <div style={{width:'90%',backgroundColor:'red'}}>{rest}</div> */}
      {companyListViewInfo.companyListView ? (
        <CompanyListModal
          visible={companyListViewInfo.companyListView}
          onVisibleCHange={handleCompanyPopup}
          userInfo={userDetail}
          activeRole={activeRole}
          handleAddCliantPopup={visibleAddClint}
          handleCompanyChange={handleCompanyChange}
          handleSwitch={handleSwitch}
          updateList={updateCompanyList}
          handleRestoreState={handleRestoreState}
          handleUpdateCompanyList={handleUpdateCompanyList}
        />
      ) : null}
      {/* {companyListViewInfo.addCliantModal ? (
        <AddClientDrawer
          isOpen={companyListViewInfo.addCliantModal}
          onClose={visibleAddClint}
          updateCompanyList={handleCompanyList}
        />
      ) : null} */}
      <footer style={{ height: "130px", width: "100%" }} />
    </Layout>
  );
};

export default connector(withRouter(IMTContent));
