import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
  Select,
  Spin,
  Switch,
} from "antd";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import { Prompt } from "react-router";
import {
  getIdentityDetails,
  IdentityDetails,
  saveIdentityDetails,
  getEmployeeEditAccess,
} from "~/api/employee";
import styles from "./IdentityDetailsPane.module.css";
import AttachableField from "~/fragment/attachable-field/AttachableField";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { LoginRole } from "~/api/auth";
import { APIState, fetchUtil } from "~/api/common";
import { noop } from "~/lib";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const bloodGroupList = [
  { name: "A+" },
  { name: "A-" },
  { name: "B+" },
  { name: "B-" },
  { name: "AB+" },
  { name: "AB-" },
  { name: "O+" },
  { name: "O-" },
];

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

interface State {
  identityFormData?: IdentityDetails;
  is_disable: boolean;
  apiState: APIState;
  isDirty: boolean;

  value?: boolean;
  edit_access_personal: boolean;
  edit_access_identity: boolean;
  edit_access_bank: boolean;
}

class IdentityDetailsPane extends Component<Props, State> {
  state: State = {
    identityFormData: undefined,
    is_disable: true,
    apiState: "loading",
    isDirty: false,
    edit_access_personal: false,
    edit_access_identity: false,
    edit_access_bank: false,
  };
  _formRef = React.createRef<FormInstance<IdentityDetails>>();

  componentDidMount() {
    this.getEmployeeEditAccess();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.currentTab === "identity" && !this.props.isTabUpdated) {
      this.props.changeTabUpdate();
      this.getEmployeeEditAccess();
    }
    if (prevProps.employeeID !== this.props.employeeID) {
      this.getEmployeeEditAccess();
    }
  }

  handlePanDocChange = (newFileName: string) => {
    this.setState({
      identityFormData: {
        ...this.state.identityFormData!,
        pan_document: newFileName,
      },
    });
  };
  handleAadharDocChange = (newFileName: string) => {
    this.setState({
      identityFormData: {
        ...this.state.identityFormData!,
        aadhar_document: newFileName,
      },
    });
  };
  handleDisabilityDocChange = (newFileName: string) => {
    this.setState({
      identityFormData: {
        ...this.state.identityFormData!,
        disability_document: newFileName,
      },
    });
  };

  async getIdentityDetails() {
    const { ok, message, data } = await getIdentityDetails(
      this.props.employeeID
    );
    if (ok) {
      const edit_access_identity = this.state.edit_access_identity;
      const isEnabled =
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.SUPERuSER;

      this.setState({ is_disable: !(isEnabled || edit_access_identity) });
      this.setState({
        identityFormData: data,
        apiState: "idle",
      });
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue(data!);
      }
    } else {
      notification.error({ message });
    }
  }

  onFinish = async (_values: IdentityDetails) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.identityDetailsPane49
    );

    if (!this.state.identityFormData?.pan_document) {
      notification.error({
        message: "Please upload a pan card",
      });
      return;
    }
    if (!this.state.identityFormData?.aadhar_document) {
      notification.error({
        message: "Please upload a aadhar card",
      });
      return;
    }

    if (_values.physical_disability.toUpperCase() !== "NO") {
      if (!this.state.identityFormData?.disability_document) {
        notification.error({
          message: "Please upload a disability document",
        });
        return;
      }
    }

    this.setState({
      apiState: "loading",
    });
    const { ok, message } = await saveIdentityDetails(
      this.state.identityFormData!
    );
    if (ok) {
      notification.success({
        message,
      });
      this.setState({ is_disable: true, isDirty: false, apiState: "success" });
    } else {
      notification.error({
        message,
      });
      this.setState({ apiState: "error" });
    }
    this.getEmployeeEditAccess();
  };

  syncValues = (
    _changesValues: Partial<IdentityDetails>,
    values: IdentityDetails
  ) => {
    values.aadhar_document = this.state.identityFormData!.aadhar_document;
    values.pan_document = this.state.identityFormData!.pan_document;
    values.disability_document = this.state.identityFormData!.disability_document;

    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    this.setState({
      identityFormData: values,
    });
  };

  async getEmployeeEditAccess() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_edit_access`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      const data = json[0];
      this.setState(data, () => {
        this.getIdentityDetails();
      });
    } else {
      notification.error({ message: "Failed to fetch" });
    }
  }

  handleEditAccess = async (value: boolean) => {
    this.setState({
      edit_access_identity: value,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/employee_edit_access`,
      {
        emp_id: this.props.employeeID,
        edit_access_personal: this.state.edit_access_personal,
        edit_access_identity: value,
        edit_access_bank: this.state.edit_access_bank,
      }
    );
    if (ok) {
      // const data = json[0];
      this.setState({
        // employeeSummaryData: data,
      });
    } else {
      notification.error({ message });
    }
  };

  render() {
    const { identityFormData } = this.state;
    const { active } = this.props;
    const bloodGroupView = !this.state.is_disable ? null : (
      <Form.Item className={styles.inputStyles} label="Blood group">
        <input
          className="ant-input"
          value={identityFormData?.blood_group}
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );

    return (
      <Spin spinning={this.state.apiState === "loading"}>
        <Form
          initialValues={identityFormData}
          onFinish={this.onFinish}
          onValuesChange={this.syncValues}
          ref={this._formRef}
        >
          <Prompt
            message="You have unsaved details. Do you still want to leave this page?"
            when={this.state.isDirty}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "93vw",
              padding: "10px",
              height: "50px",
            }}
          >
            {this.props.active &&
            (this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER) ? (
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: "10px" }}>
                      Edit access to employee
                    </span>
                    <Switch
                      style={{ display: "block" }}
                      onChange={this.handleEditAccess}
                      // defaultChecked={false}
                      checked={this.state.edit_access_identity}
                      // disabled={readOnly || disabled}
                    />
                  </>
                }
              />
            ) : null}
          </div>
          <div className={styles.tabsLayout}>
            <div className={styles.component1}>
              {bloodGroupView || (
                <Form.Item
                  className={styles.inputStyles}
                  name="blood_group"
                  label="Blood group"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter valid Blood group!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    disabled={this.state.is_disable || !active}
                  >
                    {bloodGroupList.map((blood, indx) => (
                      <Select.Option key={indx} value={blood.name}>
                        {blood.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                className={styles.inputStyles}
                name="passport_number"
                label="Passport number"
                rules={[
                  {
                    type: "string",
                    required: false,
                    message: "Please enter valid passport_number!",
                  },
                ]}
              >
                <Input readOnly={this.state.is_disable || !active} />
              </Form.Item>
              <Form.Item
                className={styles.inputStyles}
                name="criminal_records"
                label="Details of any criminal record"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message:
                      "Please enter valid details of any criminal record!",
                  },
                ]}
              >
                <Input readOnly={this.state.is_disable || !active} />
              </Form.Item>
              <Form.Item
                className={styles.inputStyles}
                name="esic_number"
                label="ESIC number"
                rules={[
                  {
                    type: "string",
                    required: false,
                  },
                ]}
              >
                <Input readOnly={this.state.is_disable || !active} />
              </Form.Item>
            </div>
            <div className={styles.component2}>
              <AttachableField
                className={styles.inputStyles}
                fieldName="pan_number"
                hashFileName={identityFormData?.pan_document}
                onHashFileChange={this.handlePanDocChange}
                readonly={this.state.is_disable || !active}
                label="PAN number"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: PAN_VALIDATION_REGEX,
                    message: "Please enter valid PAN number!",
                  },
                ]}
              />
              <AttachableField
                fieldName="aadhar_number"
                className={styles.inputStyles}
                hashFileName={identityFormData?.aadhar_document}
                onHashFileChange={this.handleAadharDocChange}
                readonly={this.state.is_disable || !active}
                label="Adhaar number"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^\d{4}\s?\d{4}\s?\d{4}$/,
                    message: "Please enter valid Adhaar number!",
                  },
                ]}
              />
              <AttachableField
                fieldName="physical_disability"
                className={styles.inputStyles}
                hashFileName={this.state.identityFormData?.disability_document}
                onHashFileChange={this.handleDisabilityDocChange}
                readonly={this.state.is_disable || !active}
                label="Physical disablitiy"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message:
                      "Please enter your answer.(enter No if not applicable)",
                  },
                ]}
              />
            </div>
          </div>

          {this.props.activeRole === LoginRole.EMPLOYEE &&
          !this.state.identityFormData?.blood_group ? (
            <div style={{ fontSize: "12px", color: "red" }}>
              *Note: You can only edit one time.
            </div>
          ) : null}

          {!this.state.is_disable ? (
            this.props.active ||
            this.props.activeRole === LoginRole.EMPLOYEE ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.apiState === "loading"}
                disabled={!active}
              >
                Save
              </Button>
            ) : null
          ) : null}
        </Form>
      </Spin>
    );
  }
}
export default connector(IdentityDetailsPane);
