/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Divider, Menu, Dropdown, Modal } from "antd";
import GSTIN_LOGO from "~/assets/GSTN-19-12-16.png";
import TALLY_ICON from "~/assets/Tally-Erp-9-with-GST-Logo.png";
import ZOHO_ICON from "~/assets/zoho-logo.png";
import MORE_ICON from "~/assets/more.png";
import EXCL_CSV_ICON from "~/assets/excel-logo.png";
import styles from "./Iconstyle.module.css";
import { action, AuthState } from "~/app/MainApp/store";
import { useDispatch, useSelector } from "react-redux";
import GstOtpFrom from "./GstOtpFrom";
import TallySync from "./TallySync";
import ZohoSync from "./ZohoSyncBack";
import AmazonCsvSync from "./AmazonCsvSync";
import SyncStatusPage from "./SyncStatusPage";
import FlipkartXlsxSync from "./FlipkartXlsxSync";
import GenericExpenseXlsxSync from "./GenericExpenseXlsxSync";
import AmazonStockTransferCsvSync from "./AmazonStockTransferCsvSync";
import PetpoojaXlsxSync from "./PetpoojaXlsxSync";
import MeeshoXlsxSync from "./MeeshoXlsxSync";
import GenericXlsxSync from "./GenericXlsxSync";
import { getSyncStatus } from "~/api/synchronize";
import SyncBackwardTallyModal from "./SyncBackwardTallyModal";
import { sleep } from "~/utils";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import VanillaButton from "~/component/VanillaButton";
import VideoDrawer from "~/component/VideoDrawer";
import AmazoneLogo from "~/assets/Logo/Amazon.svg";
import FlipkartLogo from "~/assets/Logo/Flipkart.png";
import MeeshoLogo from "~/assets/Logo/Meesho.png";
import GenericLogo from "~/assets/Logo/genericLogo.png";
import PetpoojacLogo from "~/assets/Logo/Petpooja.png";
import Zoho_logo from "~/assets/Logo/Zoho_Logo.png";
import Shopify_logo from "~/assets/Logo/Shopify_Logo.png";

const { modal } = action;

const sources = [
  {
    category: "xlsx",
    label: "Excel formats",
    items: [
      {
        label: "Flipkart (Sales Report)",
        image: EXCL_CSV_ICON,
        component: FlipkartXlsxSync,
        type: "xlsx",
        isEnable: true,
        format: "FlipkartXls",
        sourceIndex: 5,
        src: FlipkartLogo,
      },
      {
        label: "Meesho (Forward & Return)",
        image: EXCL_CSV_ICON,
        component: MeeshoXlsxSync,
        type: "xlsx",
        isEnable: true,
        format: "MeeshoXls",
        sourceIndex: 6,
        src: MeeshoLogo,
      },
      {
        label: "Generic sales (Lite & Detailed)",
        image: EXCL_CSV_ICON,
        component: GenericXlsxSync,
        type: "xlsx",
        isEnable: true,
        format: "GenericXls",
        sourceIndex: 7,
        src: GenericLogo,
      },
      {
        label: "Petpooja",
        image: EXCL_CSV_ICON,
        component: PetpoojaXlsxSync,
        type: "xlsx",
        isEnable: true,
        format: "GenericExpenseXls",
        sourceIndex: 8,
        src: PetpoojacLogo,
      },

      {
        label: "Generic Expense",
        image: EXCL_CSV_ICON,
        component: GenericExpenseXlsxSync,
        type: "xlsx",
        isEnable: true,
        format: "GenericExpenseXls",
        sourceIndex: 9,
        src: GenericLogo,
      },
      /* {
        label: "GSTN",
        image: EXCL_CSV_ICON,
        component: "GSTNCsvSync",
        type: "xlsx",
        format: "gstinXlsx",
        isEnable: false,
        sourceIndex: -1,
      }, */
    ],
  },
  {
    category: "csv",
    label: "CSV formats",
    items: [
      {
        label: "Amazon MTR (B2B & B2C)",
        image: EXCL_CSV_ICON,
        component: AmazonCsvSync,
        type: "CSV",
        format: "AmazonCsv",
        isEnable: true,
        sourceIndex: 3,
        src: AmazoneLogo,
        // groupLabel: "",
      },
      {
        label: "Amazon MTR (Stock transfer)",
        image: EXCL_CSV_ICON,
        component: AmazonStockTransferCsvSync,
        type: "CSV",
        format: "AmazonStkTrfCsv",
        isEnable: true,
        sourceIndex: 4,
        src: AmazoneLogo,
        // groupLabel: "",
      },
    ],
  },
  {
    category: "highlights",
    label: "Preferred",
    items: [
      {
        label: "GSTIN",
        image: GSTIN_LOGO,
        component: GstOtpFrom,
        type: "api",
        sourceIndex: 0,
      },
      {
        label: "Tally",
        image: TALLY_ICON,
        component: TallySync,
        type: "api",
        sourceIndex: 1,
      },
      {
        label: "Zoho",
        image: ZOHO_ICON,
        component: ZohoSync,
        type: "api",
        sourceIndex: 2,
      },
    ],
  },
  {
    category: "api",
    label: "Api integration",
    items: [
      {
        label: "Zoho",
        image: EXCL_CSV_ICON,
        component: "ZohoSync",
        type: "api",
        isEnabled: false,
        format: "zohoCsv",
        src: Zoho_logo,
      },
      {
        label: "Shopify",
        image: EXCL_CSV_ICON,
        component: "Shopify",
        type: "api",
        isEnabled: false,
        format: "ShopifyCsv",
        src: Shopify_logo,
      },
    ],
  },
];

const SourceSelectionModal = () => {
  const [sourceIndex, setSourceIndex] = useState<number>(-1);
  const [isCheckingSyncStatus, setIsCheckingSyncStatus] = useState<boolean>(
    false
  );

  const {
    sourceSelectionModal,
    startSyncFromSourceModal,
    syncStatusModal,
    tallyBackSyncSelectModal,
    tallyBackSyncSelectModalNew,
    sourceName,
    syncProcessStatus,
    syncStatusDetail,
    syncInterval,
    sumOfError,
    sumOfTotal,
    sumTotalLoad,
    /* isSyncing, */
    processId,
    elapsedTime,
  } = useSelector((state: AuthState) => state.modalSlice);

  // const gstin = useSelector(
  //   (state: AuthState) => state.mainAppSlice.user.gstin
  // );
  // console.log(gstin);
  const userData = localStorage.getItem("imt__user");
  const userParseData = JSON.parse(userData!);
  const dispatch = useDispatch();

  const gstin = userParseData.gstin;

  const handleCloseModal = () => {
    dispatch(action.modal.hideSourceSelectionModal());
    dispatch(action.modal.hideStartSyncFromSourceModal());
    dispatch(action.modal.hideSyncStatusModal());
  };

  const handleSourceClick = (si: number) => () => {
    dispatch(action.modal.hideSourceSelectionModal());
    dispatch(action.modal.showStartSyncFromSourceModal());
    setSourceIndex(si);
  };

  const setInterValStore = (data: any) => {
    dispatch(action.modal.setSyncStatus(data));
  };

  const intervalData = (intervalid: any) => {
    dispatch(action.modal.startInterval(intervalid));
  };

  // This is for the dropdown menu
  const handleSourceSelect = (selectedItem: any) => {
    setSourceIndex(+selectedItem.key);
    dispatch(action.modal.hideSourceSelectionModal());
    dispatch(action.modal.showStartSyncFromSourceModal());
  };

  const handleBack = () => {
    dispatch(action.modal.hideStartSyncFromSourceModal());
    dispatch(action.modal.showSourceSelectionModal());
  };

  const handleStartSyncModalClose = () => {
    dispatch(action.modal.hideStartSyncFromSourceModal());
  };

  const handleSyncBackTallyOk = useCallback(() => {
    dispatch(action.modal.setTallyBackSyncSelectModal({ isVisible: false }));
    sleep(100).then(() => dispatch(action.modal.showSyncStatusModal()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSyncBackTallyClose = useCallback(() => {
    dispatch(action.modal.setTallyBackSyncSelectModal({ isVisible: false }));
    dispatch(action.modal.setTallyBackSyncSelectModalNew({ isVisible: true }));
    // sleep(100).then(() => dispatch(action.modal.showSyncStatusModal()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSyncBackTallyOkNew = useCallback(() => {
    dispatch(action.modal.setTallyBackSyncSelectModalNew({ isVisible: false }));
    sleep(100).then(() => dispatch(action.modal.showSyncStatusModal()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSyncBackTallyCloseNew = useCallback(() => {
    dispatch(action.modal.setTallyBackSyncSelectModalNew({ isVisible: false }));
    // sleep(100).then(() => dispatch(action.modal.showSyncStatusModal()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBackwardSyncStart = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart249
    );

    // this.props.showSyncStatusModal();
    dispatch(action.modal.setTallyBackSyncSelectModal({ isVisible: true }));
    handleSyncBackTallyCloseNew();
    // this.setState({
    //   openBackwardSyncModal: true,
    // });
  };
  const [openVideoDrawer, setOpenVideoDrawer] = React.useState<boolean>(false);
  useEffect(() => {
    const forceRunGetSyncStatus = async () => {
      if (sourceSelectionModal.isVisible) {
        setIsCheckingSyncStatus(true);
        const { /*  ok, */ json /* , message */ } = await getSyncStatus();
        dispatch(modal.setSocketData(json));
        const isSyncingNow = json[0]?.processState === "RUNNING" ? true : false;
        setIsCheckingSyncStatus(false);
        if (isSyncingNow) {
          dispatch(action.modal.hideSourceSelectionModal());
          dispatch(action.modal.hideStartSyncFromSourceModal());
          dispatch(action.modal.showSyncStatusModal());
        }
      }
    };
    forceRunGetSyncStatus();
    // dispatch will never change, omiting it from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSelectionModal.isVisible]);

  const renderMenu = () => (
    <Menu onClick={handleSourceSelect}>
      {sources
        .filter((s: any) => s.category !== "highlights")
        .map((sf: any, idx: number) => (
          <Menu.ItemGroup title={sf.label} key={idx + 99}>
            {sf.items.map((item: any, index: number) => (
              <Menu.Item
                disabled={!item.isEnable}
                key={item.sourceIndex}
                className={styles.sourceMenuActive}
              >
                <strong>
                  <img src={item.src} className={styles.logo} alt="" />
                  {item.label}
                </strong>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
    </Menu>
  );

  const getSourceFromIndex = (sIdx: number) => {
    let source: any = null;
    sources.forEach((s: any) => {
      const src = s.items.find((si: any) => si.sourceIndex === sIdx);
      if (src) {
        source = src;
        return false;
      }
    });
    return source;
  };

  const renderSyncFromSourceContent = () => {
    let Component: any = null;
    let source: any = null;
    sources.forEach((s: any) => {
      const src = s.items.find((si: any) => si.sourceIndex === sourceIndex);
      if (src) {
        Component = src?.component;
        source = src;
        return false;
      }
    });
    return (
      <Component
        handleBackPress={handleBack}
        closeModal={handleStartSyncModalClose}
        source={source}
      />
    );
  };

  const renderSourceSelectionContent = () => {
    const h = sources.find((s: any) => s.category === "highlights");
    const highlights: Array<any> = h && h.items ? h.items : [];
    return (
      <div style={{ textAlign: "center" }}>
        <Typography.Title level={3} style={{ color: "var(--grey-1)" }}>
          Choose a source
          <br />
        </Typography.Title>

        <Divider
          style={{
            marginTop: "60px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          {highlights.map((hl: any, hlId: number) => (
            <div
              key={hl.label}
              onClick={handleSourceClick(hl.sourceIndex)}
              className={styles.hoverEffect}
              style={{
                cursor: gstin ? "pointer" : "not-allowed",
              }}
            >
              <div
                style={{
                  pointerEvents: gstin ? "initial" : "none",
                }}
              >
                <img
                  alt={hl.label}
                  src={hl.image}
                  className={styles.talyIconChoser}
                />
              </div>
            </div>
          ))}

          <div className={styles.hoverEffect}>
            <Dropdown overlay={renderMenu()}>
              <img
                alt="More_logo"
                src={MORE_ICON}
                style={{
                  width: "70px",
                  objectFit: "contain",
                  opacity: 0.5,
                  marginTop: "1.2rem",
                }}
              />
            </Dropdown>
          </div>
        </div>
        <Divider style={{ marginBottom: "60px" }} />
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Fetch data using GSTIN"
        style={{ minHeight: "700px", minWidth: "300px", width: "30%" }}
        destroyOnClose={true}
        className="modal-headless"
        maskClosable={false}
        footer={false}
        visible={
          sourceSelectionModal.isVisible ||
          startSyncFromSourceModal.isVisible ||
          syncStatusModal.isVisible
        }
        onCancel={handleCloseModal}
        wrapClassName={styles.sourceSelectionModalBumpZIndex}
      >
        {isCheckingSyncStatus ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: 200,
              display: "flex",
              flex: 1,
            }}
          >
            Checking sync status ...
          </div>
        ) : (
          <>
            {sourceSelectionModal.isVisible && renderSourceSelectionContent()}
            {startSyncFromSourceModal.isVisible &&
              renderSyncFromSourceContent()}
            {syncStatusModal.isVisible && (
              <SyncStatusPage
                sourceName={sourceName}
                syncProcessStatus={syncProcessStatus}
                syncStatusDetail={syncStatusDetail}
                sourceLabel={getSourceFromIndex(sourceIndex)?.label}
                icon={getSourceFromIndex(sourceIndex)?.image}
                setInterValStore={setInterValStore}
                intervalData={intervalData}
                syncInterval={syncInterval}
                sumOfError={sumOfError}
                sumOfTotal={sumOfTotal}
                sumTotalLoad={sumTotalLoad}
                processId={processId}
                elapsedTime={elapsedTime}
              />
            )}
          </>
        )}
      </Modal>

      {tallyBackSyncSelectModal.isVisible && (
        <SyncBackwardTallyModal
          isOpen={tallyBackSyncSelectModal.isVisible}
          onOk={handleSyncBackTallyOk}
          onClose={handleSyncBackTallyClose}
          onCloseNew={handleSyncBackTallyCloseNew}
        />
      )}
      {tallyBackSyncSelectModalNew.isVisible && (
        <Modal
          title="Heads up !"
          visible={tallyBackSyncSelectModalNew.isVisible}
          onOk={handleBackwardSyncStart}
          onCancel={handleSyncBackTallyCloseNew}
        >
          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
            }}
          >
            In the next step, you can select items that will be synced back to
            Tally. However, the current version of Tally back sync has some
            limitations and requires a few steps to be followed before syncing
            back.
            <br />
            Follow the steps carefully as described in the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://docs.google.com/document/d/e/2PACX-1vTrGDMsgRduDAh8PYWFvYnvwRT4CYxDnpOGUug_Gc7-QScywPrvmwVC5jkOnwYTgmL3arX75ePdAynD/pub"
              }
            >
              {"manual"}
            </a>{" "}
            .<br /> Also, you can watch this{" "}
            <VanillaButton
              text="this video"
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => {
                setOpenVideoDrawer(true);
              }}
            />{" "}
            to understand the back-sync process
          </Typography.Title>
        </Modal>
      )}
      <VideoDrawer
        url="https://youtu.be/T2L-swhPxWs"
        title="Cheqd tally sync back"
        // tslint:disable-next-line: jsx-no-lambda
        onClose={() => setOpenVideoDrawer(false)}
        openDrawer={openVideoDrawer}
      />
    </>
  );
};

export default SourceSelectionModal;
