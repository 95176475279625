import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Tabs,
  Space,
  Button,
  notification,
  Modal,
  Form,
  Row,
  Input,
  Menu,
  Dropdown,
} from "antd";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import PersonalDetailsPane from "./DetailsTabPane/PersonalDetailsPane";
import IdentityDetailsPane from "./DetailsTabPane/IdentityDetailsPane";
import BankDetailsPane from "./DetailsTabPane/BankDetailsPane";
import SalaryDetailsPane from "./DetailsTabPane/SalaryDetailsPane";
import InvestDetailPane from "./DetailsTabPane/InvestDetailPane";
import TermsAndCond from "./DetailsTabPane/TermsAndCond";
import BenefitPane from "./DetailsTabPane/BenefitPane";
import { fetchUtil } from "~/api/common";
import { LoginRole } from "~/api/auth";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import DatePicker from "~/component/antd-overrides/DatePicker";
import TermsModalView from "~/component/TermsModalView";
import { isFuture } from "date-fns";
import { registerEvent } from "~/analytics";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import styles from "./EmployeePage.module.css";
import "./ant.css";
import * as ga from "~/contants/gaConstants";
import { MenuOutlined, MoreOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

type RouteParams = {
  id: string;
  slug: string;
  emp_name: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

const mapStateToProps = (state: AuthState, ownProps: RouteProps) => {
  let employeeID = -1;
  let userDetails = undefined as any;
  const routeParams = ownProps.match.params;
  userDetails = state.mainAppSlice.user;
  if (routeParams.id) {
    employeeID = +routeParams.id;
  } else if (routeParams.slug === "me") {
    employeeID = state.mainAppSlice.user.id;
  }
  return {
    activeRole: state.mainAppSlice.user.activeRole,
    employeeID,
    termsAndCondAgree: state.mainAppSlice.user.terms_conditions_agree,
    userDetails,
  };
};

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  handleTermsModal: (agreeTerms: boolean) =>
    dispatch(action.auth.handleTermsModal(agreeTerms)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteProps, PropsFromRedux {
  isActive: boolean;
  history: any;
}

const TabKeys = [
  "personal",
  "identity",
  "bank",
  "salary",
  "terms",
  "benefit",
  "invest_detail",
] as const;
type TabTypes = typeof TabKeys[number];
interface State {
  tab: TabTypes;
  visible: boolean;
  checkTermsLength: number;
  currentTab: string;
  isTabUpdated: boolean;
  active: boolean;
}

class EmployeeDetailsPage extends Component<Props, State> {
  state: State = {
    tab: "personal",
    visible: false,
    checkTermsLength: 0,
    currentTab: "personal",
    isTabUpdated: true,
    active: true,
  };
  logout = (key: string) => {
    notification.close(key);
    localStorage.clear();
    localStorage.clear();
    this.props.history!.push("/");
  };

  _formRef?: FormInstance<any>;
  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let tabName: TabTypes;
    if (!query.has("tab")) {
      tabName = "personal";
    } else {
      const tabKey: any = query.get("tab");
      if (TabKeys.includes(tabKey)) {
        tabName = tabKey;
      } else {
        tabName = "personal";
      }
    }
    if (prevState.tab === tabName) {
      return null;
    } else {
      return { tab: tabName };
    }
  }

  componentDidMount() {
    // const user = JSON.parse(localStorage.getItem("imt__user")!);
    // if (this.props.activeRole === LoginRole.EMPLOYEE && !user.membership_plan) {
    //   notification.error({
    //     message:
    //       "Please contact your Admin or Superuser to reactivate the membership plan",
    //   });
    // }
    if (this.props.activeRole === LoginRole.EMPLOYEE) {
      this.getContent();
    }
    const activeData = this.props.history.location.state as any;
    if (activeData) {
      this.setState({
        active: activeData?.isActive,
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.activeRole !== this.props.activeRole) {
      setTimeout(() => {
        window.location.reload();
        this.getContent();
      }, 150);
    }
  }

  handleTabChange = (activeKey: string) => {
    this.setState({ currentTab: activeKey, isTabUpdated: false });

    this.props.history.replace({
      search: "?tab=" + activeKey,
      state: { isActive: this.state.active },
    });
  };

  changeTabUpdate = () => {
    this.setState({ isTabUpdated: true });
  };

  onFormRef = (instance?: FormInstance<any> | null) => {
    this._formRef = instance || undefined;
  };

  onDelete = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.employeeDetailsPage64
    );
    this.setState({
      visible: true,
    });
  };

  handleOk = async (_e: React.MouseEvent<HTMLElement>) => {
    if (!this._formRef) {
      return;
    }

    this._formRef
      .validateFields()
      .then(async (value) => {
        const { ok, message } = await fetchUtil("POST", "/delete_user", {
          emp_id: this.props.match.params.id,
          leaving_date: value.leaving_date,
        });
        if (ok) {
          notification.success({
            message,
          });
          if (this.props.employeeID === this.props.userDetails.id) {
            const key = `open${Date.now()}`;
            const btn = (
              <Button
                type="primary"
                size="small"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(e: React.MouseEvent) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.employeeDetailsPage65
                  );
                  this.logout(key);
                }}
              >
                Log out
              </Button>
            );
            notification.info({
              message:
                "You have assigned yourself a new role successfully. Please signout and login again to see the changes",
              duration: 8,
              btn,
              key,
            });
          }
        } else {
          notification.error({
            message,
          });
        }
        this.props.history.goBack();
        this.setState({
          visible: false,
        });
      })
      .catch((reason) => {
        console.warn("Form error", reason);
      });
  };

  handleCancel = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.employeeDetailsPage66
    );
    this.setState({
      visible: false,
    });
  };

  async getContent() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_terms_conditions`,
      {
        emp_id: this.props.employeeID,
      }
    );
    if (ok) {
      this.props.handleTermsModal(json[0]?.terms_conditions_agree);
      this.setState({
        checkTermsLength: json[0].last_published_terms_conditions
          ? json[0].last_published_terms_conditions?.length
          : 0,
      });
    } else {
      if (json?.length !== 0) {
        notification.error({ message });
      }
    }
  }

  render() {
    const { employeeID, activeRole } = this.props;
    const { currentTab, isTabUpdated, active } = this.state;
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => this.onDelete()}>
          Deactivate
        </Menu.Item>
      </Menu>
    );
    return (
      <IMTContent fullwidth withoutMargin={true}>
        <Helmet>
          <title>{titles.EmployeeDetailsPage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Employee", `Employee details`, `(${employeeID})`]}
          actions={
            <>
              {activeRole === LoginRole.ADMIN ||
              activeRole === LoginRole.SUPERuSER ? (
                active ? (
                  <>
                    <span className={styles.actionButtons}>
                      <Button
                        danger
                        onClick={this.onDelete}
                        style={{ marginLeft: "10px" }}
                      >
                        Deactivate
                      </Button>
                    </span>
                    <span className={styles.actionMenu}>
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <MoreOutlined
                          style={{ fontSize: "20px", margin: "0px 10px" }}
                        />
                      </Dropdown>
                    </span>
                  </>
                ) : null
              ) : null}
            </>
          }
          activeRole={activeRole}
          empActiveStatus={active}
          style={{ marginLeft: 0 }}
        />
        <div className={styles.commonContent}>
          {activeRole === LoginRole.EMPLOYEE ? (
            this.props.termsAndCondAgree ||
            this.state.checkTermsLength === 0 ? (
              <Tabs
                activeKey={this.state.tab}
                onChange={this.handleTabChange}
                style={{ width: "100%" }}
              >
                <TabPane tab="Personal" key="personal">
                  <PersonalDetailsPane
                    currentTab={currentTab}
                    employeeID={employeeID}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Identity" key="identity">
                  <IdentityDetailsPane
                    currentTab={currentTab}
                    employeeID={employeeID}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Bank" key="bank">
                  <BankDetailsPane
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    currentTab={currentTab}
                    employeeID={employeeID}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Salary" key="salary">
                  <SalaryDetailsPane
                    currentTab={currentTab}
                    employeeID={employeeID}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Benefit" key="benefit">
                  <BenefitPane
                    employeeID={employeeID}
                    currentTab={currentTab}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Investment details" key="invest_detail">
                  <InvestDetailPane
                    currentTab={currentTab}
                    employeeID={employeeID}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
                <TabPane tab="Employee T & C" key="terms">
                  <TermsAndCond
                    currentTab={currentTab}
                    employeeID={employeeID}
                    isTabUpdated={isTabUpdated}
                    changeTabUpdate={this.changeTabUpdate}
                    active={active}
                  />
                </TabPane>
              </Tabs>
            ) : (
              <TermsModalView
                employeeID={employeeID}
                visible={this.props.termsAndCondAgree}
              />
            )
          ) : (
            <Tabs
              activeKey={this.state.tab}
              onChange={this.handleTabChange}
              style={{ width: "100%" }}
            >
              <TabPane tab="Personal" key="personal">
                <PersonalDetailsPane
                  currentTab={currentTab}
                  employeeID={employeeID}
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Identity" key="identity">
                <IdentityDetailsPane
                  currentTab={currentTab}
                  employeeID={employeeID}
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Bank" key="bank">
                <BankDetailsPane
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  currentTab={currentTab}
                  employeeID={employeeID}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Salary" key="salary">
                <SalaryDetailsPane
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  currentTab={currentTab}
                  employeeID={employeeID}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Benefit" key="benefit">
                <BenefitPane
                  employeeID={employeeID}
                  currentTab={currentTab}
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Investment details" key="invest_detail">
                <InvestDetailPane
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  currentTab={currentTab}
                  employeeID={employeeID}
                  active={active}
                />
              </TabPane>
              <TabPane tab="Employee T & C" key="terms">
                <TermsAndCond
                  currentTab={currentTab}
                  employeeID={employeeID}
                  isTabUpdated={isTabUpdated}
                  changeTabUpdate={this.changeTabUpdate}
                  active={active}
                />
              </TabPane>
            </Tabs>
          )}
          <Modal
            title="Deactivate employee"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            destroyOnClose
            maskClosable={false}
          >
            <Form
              initialValues={{ emp_name: this.props.match.params.emp_name }}
              ref={this.onFormRef}
            >
              <Row>
                <Form.Item
                  className={commonStyle["w-50"]}
                  name="emp_name"
                  label="Employee name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={true} />
                </Form.Item>
                <Form.Item
                  name="leaving_date"
                  label="Leaving date"
                  rules={[
                    {
                      required: true,
                      message: "Leaving date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className={commonStyle["w-100"]}
                    format="DD-MMM, YYYY"
                    disabledDate={isFuture}
                  />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        </div>
      </IMTContent>
    );
  }
}

export default connector(EmployeeDetailsPage);
