import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Payment: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      height={props?.height || 28}
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2670 4930 c-19 -19 -20 -33 -20 -389 0 -203 3 -377 6 -386 11 -27
53 -38 80 -20 l24 15 0 385 c0 335 -2 386 -16 399 -21 22 -50 20 -74 -4z"
        />
        <path
          d="M1570 4500 c-19 -19 -20 -33 -20 -314 0 -322 1 -326 59 -326 51 0 51
2 51 326 0 262 -2 305 -16 318 -21 22 -50 20 -74 -4z"
        />
        <path
          d="M3770 4500 c-19 -19 -20 -33 -20 -308 0 -317 3 -332 59 -332 51 0 51
2 51 326 0 262 -2 305 -16 318 -21 22 -50 20 -74 -4z"
        />
        <path
          d="M2575 3880 c-188 -22 -350 -68 -510 -145 -283 -137 -505 -356 -644
-637 -107 -218 -147 -402 -138 -642 6 -157 18 -230 64 -380 30 -99 52 -123 96
-107 39 15 41 41 8 148 -45 146 -54 212 -54 383 1 179 21 295 80 445 48 122
66 155 137 260 177 260 469 461 776 535 326 78 670 31 969 -132 157 -87 322
-235 431 -389 208 -295 281 -680 195 -1025 -59 -235 -152 -400 -339 -602 -47
-50 -86 -98 -86 -105 0 -19 22 -45 40 -47 36 -5 50 4 123 76 195 192 323 426
386 709 18 78 27 258 19 359 -60 726 -630 1266 -1373 1300 -60 2 -141 1 -180
-4z"
        />
        <path
          d="M2427 3191 c-15 -27 -27 -51 -27 -55 0 -3 43 -6 95 -6 104 0 140 -10
193 -54 58 -50 47 -56 -101 -56 l-134 0 -26 -49 c-15 -27 -27 -51 -27 -55 0
-3 77 -6 171 -6 l172 0 -7 -32 c-19 -94 -99 -149 -228 -156 l-78 -5 0 -51 c0
-50 4 -57 168 -294 l167 -242 68 0 c47 0 67 4 67 13 0 6 -72 120 -160 252
l-160 240 73 22 c112 34 195 118 212 215 7 37 7 37 62 40 54 3 54 3 84 56 l31
52 -86 0 c-85 0 -86 0 -92 28 -4 15 -13 39 -20 54 l-14 27 77 3 78 3 28 53 28
52 -294 0 -294 0 -26 -49z"
        />
        <path
          d="M1710 1699 c-216 -24 -429 -116 -636 -275 -82 -62 -86 -64 -144 -64
l-60 0 0 94 c0 77 -3 98 -19 117 l-19 24 -364 3 c-199 2 -377 0 -395 -3 -67
-12 -63 40 -63 -779 0 -707 1 -744 18 -759 17 -15 60 -17 415 -17 l396 0 15
22 c12 17 16 49 16 133 l0 110 187 -3 c226 -3 216 -1 728 -112 226 -49 441
-94 478 -101 99 -16 667 -6 762 14 122 27 173 46 986 381 580 239 809 338 844
365 137 103 143 314 13 426 -49 41 -133 75 -188 75 -61 0 -299 -52 -752 -166
-230 -57 -419 -104 -421 -104 -2 0 -10 19 -17 43 -43 146 -192 285 -349 327
-24 6 -107 15 -185 20 -179 11 -267 30 -553 124 -348 113 -463 131 -693 105z
m337 -114 c43 -8 153 -40 243 -70 210 -71 382 -122 451 -135 30 -6 122 -15
205 -20 171 -10 228 -27 309 -88 28 -21 65 -59 82 -86 31 -45 61 -121 52 -130
-8 -8 -259 -56 -379 -72 -69 -9 -192 -17 -275 -17 -207 -2 -324 26 -421 99
-36 28 -76 31 -98 8 -41 -40 -5 -84 119 -144 121 -60 199 -74 405 -74 275 1
461 35 1220 223 543 135 698 167 753 157 42 -8 100 -54 123 -97 30 -58 12
-151 -38 -197 -20 -18 -1060 -456 -1574 -662 -197 -79 -236 -85 -624 -85
l-335 0 -415 92 c-534 118 -563 123 -792 123 l-188 0 0 419 0 420 58 0 c77 0
132 21 208 81 240 189 450 266 730 269 56 1 137 -6 181 -14z m-1297 -765 l0
-670 -315 0 -315 0 0 670 0 670 315 0 315 0 0 -670z"
        />
      </g>
    </svg>
  );
};

export default Payment;
