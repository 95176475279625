import React, { Component, ReactNode } from "react";
import { Tooltip, Typography } from "antd";
import cx from "classnames";
import LeftArrow from "~/component/imt-icon/LeftArrow";

import styles from "./IMTPageHeader.module.css";
import { withRouter, RouteComponentProps } from "react-router";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props extends RouteComponentProps {
  className?: string;
  breadcumTexts: Array<React.ReactNode>;
  breadcumSubTexts?: string;
  breadcumTooltip?: string | null;
  breadcumTooltipIcon?: any;
  tooltipIconStyle?: any;
  leftImage?: string | null;
  actions?: ReactNode;
  empActiveStatus?: boolean;
  style?: any;
  activeRole?: number;
}

class IMTPageHeader extends Component<Props, {}> {
  state = {};

  onClickBackBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.imtPageHeader292
    );
    if (this.props.history.length !== 1) {
      const pathName = this.props.history.location.pathname;
      const searchIteam = this.props.history.location.state as any;
      const setMonth = this.props.history.location.state as any;
      const setMonth2 = this.props.history.location as any;
      if (pathName.includes("salary")) {
        this.props.history.push("/app/payroll");
        this.props.history.push(
          `/app/payroll${
            setMonth?.search
              ? setMonth?.search
              : setMonth2?.search
              ? setMonth2?.search
              : ""
          }`
        );
      } else {
        if (this.props.empActiveStatus !== undefined) {
          if (this.props.empActiveStatus) {
            this.props.history.push(`/app/employee`, {
              activeStatus: this.props.empActiveStatus,
            });
          } else {
            this.props.history.push(`/app/employee`, {
              activeStatus: this.props.empActiveStatus,
            });
          }
        } else {
          if (window.location.pathname.includes("sellerdebitNote")) {
            this.props.history.push(
              `/app/expense/sellerdebitNote${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("purchasedebitNote")) {
            this.props.history.push(
              `/app/expense/purchasedebitNote${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("creditNote")) {
            this.props.history.push(
              `/app/invoice/creditNote${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("debitNote")) {
            this.props.history.push(
              `/app/invoice/debitNote${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("invoice")) {
            this.props.history.push(
              `/app/invoice${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("expense")) {
            this.props.history.push(
              `/app/expense${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("reimbursement")) {
            this.props.history.push(
              `/app/reimbursement${
                setMonth?.search
                  ? setMonth?.search
                  : setMonth2?.search
                  ? setMonth2?.search
                  : ""
              }`
            );
          } else if (window.location.pathname.includes("salary-detail")) {
            this.props.history.push("/app/payroll");
          } else if (window.location.pathname.includes("employee")) {
            this.props.history.push(`/app/employee`, {
              activeStatus: this.props.empActiveStatus,
            });
          } else if (window.location.pathname.includes("vendor")) {
            this.props.history.push(
              searchIteam?.name !== ""
                ? `/app/masters/vendor?search_item=${searchIteam?.name}`
                : `/app/masters/vendor`
            );
          } else {
            this.props.history.goBack();
          }
        }
      }
    }
    if (this.props.history.length === 1) {
      // const p = this.props.history.location.pathname;
      // const pathName = p.substring(0, p.lastIndexOf("/") + 1);
      const pathName = this.props.history.location.pathname;
      if (pathName.includes("salary-detail")) {
        this.props.history.push("/app/payroll");
      }
      if (pathName.includes("invoice")) {
        this.props.history.push("/app/invoice");
      }
      if (pathName.includes("expense")) {
        this.props.history.push("/app/expense");
      }
      if (pathName.includes("reimbursement")) {
        this.props.history.push("/app/reimbursement");
      }
      if (pathName.includes("employee")) {
        this.props.history.push(`/app/employee`, {
          activeStatus: this.props.empActiveStatus,
        });
      }
    }
    // TODO: if by back we arrive outside of current app then push to /app instead of going back!
  };

  render() {
    const {
      breadcumTexts,
      actions,
      breadcumSubTexts,
      leftImage,
      breadcumTooltip,
      breadcumTooltipIcon,
      tooltipIconStyle,
      activeRole,
    } = this.props;
    const breadCumTitle: Array<ReactNode> = [];
    breadcumTexts.forEach((t: any, i: any) => {
      // Not First
      if (i > 0 && i < 2) {
        breadCumTitle.push(
          <Typography.Title key={i} level={3} className={styles.separator}>
            &gt;
          </Typography.Title>
        );
      }
      if (i === breadcumTexts.length - 1) {
        breadCumTitle.push(
          <Typography.Title key={i + 10} level={3} className={styles.title}>
            {t}
          </Typography.Title>
        );
      } else {
        breadCumTitle.push(
          <Typography.Title
            key={i + 10}
            level={3}
            className={styles.title}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t}
          </Typography.Title>
        );
      }
    });

    const countGrids = () => {
      let text = "";
      for (let i = 0; i < breadcumTexts.length; i++) {
        if (i === breadcumTexts.length - 1) {
          text = `${text} 1fr`;
        } else {
          text = `${text} auto`;
        }
      }
      if (breadcumTexts.length > 1) {
        text = `auto ${text}`;
      }
      return text;
    };

    return (
      <>
        <div
          className={styles.boxPhView}
          style={{
            width: `clac(100% - 48px)`,
            height: "32.5px",
            margin: `24px 24px 24px ${
              breadcumTexts.length > 1 ? "24px" : "0px"
            }`,
            gridTemplateColumns: `${
              breadcumTexts.length > 1 && activeRole !== 2 ? "30px" : "0px"
            } auto auto`,
          }}
        >
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {this.props.location.pathname !== "/app/employee/me" &&
            breadcumTexts.length > 1 ? (
              <LeftArrow
                className={styles.backAction}
                role="button"
                onClick={this.onClickBackBtn}
              />
            ) : null}
          </div>
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <div style={{ display: "grid", gridTemplateColumns: countGrids() }}>
              {breadCumTitle}
            </div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {actions}
          </div>
        </div>
        <div className={styles.boxPhLaptopView}>
          <div
            style={this.props.style}
            className={cx(styles.wrapper, this.props.className)}
          >
            <div
              style={{
                display: "flex",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              {leftImage && (
                <div className={styles.bcLeftImgWrapper}>
                  <img
                    className={styles.bcLeftImg}
                    src={leftImage}
                    alt="logo"
                  />
                </div>
              )}
              <div style={{ width: "100%" }}>
                <div className={cx(styles.base)}>
                  {this.props.location.pathname !== "/app/employee/me" &&
                  breadcumTexts.length > 1 ? (
                    <LeftArrow
                      className={styles.backAction}
                      role="button"
                      onClick={this.onClickBackBtn}
                    />
                  ) : null}
                  {breadCumTitle}
                  {breadcumTooltip ? (
                    <Tooltip placement="bottom" title={breadcumTooltip}>
                      <div className={styles.tooltip}>
                        {breadcumTooltipIcon || (
                          <InfoCircleOutlined
                            style={{
                              ...(tooltipIconStyle || {}),
                              color: "var(--primary-color)",
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                  ) : (
                    <div>{breadcumTooltipIcon}</div>
                  )}
                  <div className={styles.stretch} />
                  <div className={styles.actionWrap}>{actions}</div>
                </div>
                {breadcumSubTexts && (
                  <div className={styles.breadcumSubTexts}>
                    {breadcumSubTexts}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(IMTPageHeader);
